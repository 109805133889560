@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
  background-color: white;
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: 67.5vh;
  max-height: 600px;
  padding: 0;

  @media (--viewportMedium) {
    min-height: 500px;
    height: 70vh;
    max-height: none;
  }

  @media (--viewportLarge) {
    max-height: 800px;
    min-height: 600px;
    height: calc(70vh - var(--topbarHeightDesktop));
  }
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 32px;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }

  @media (--viewportMedium) {
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 60px;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: auto;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.sectionContentFirstChild {
  margin: 0 50px;
  margin-top: 50px;
  margin-bottom: 50px;

  @media screen and (max-width: 768px) {
    margin: 10px;
  }
}

/*
.howItWorksSection {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 126px;
  position: relative;
}

.howItWorksBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 200px;
  position: absolute;
  top: 30px;
  left: 50px;
  padding: 20px;
  margin: 10px;
  border: 2px solid black;
  background-color: white;
  color: black;
  border-radius: 10px;
}

.howItWorksBoth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  border-radius: 10px;
  padding: 10px;
}

.howItWorksTitle {
  text-align: center;
  @apply --marketplaceH1FontStyles;
  margin-bottom: 20px;
}

.howItWorksOwners,
.howItWorksRenters {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.howItWorksHeader {
  text-align: center;
  @apply --marketplaceH1FontStyles;
  margin-bottom: 20px;
}

.howItWorksSubSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.howItWorksStep {
  margin: 0 10px;
  text-align: center;
}

.howItWorksEmoji {
  margin-left: 5px;
}

@media screen and (max-width: 1000px) {
  .howItWorksSection {
    flex-direction: column;
  }

  .howItWorksBox {
    position: static;
    width: 100%;
    margin: 20px 0;
  }

  .howItWorksBoth {
    flex-direction: column;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .howItWorksSection {
    padding: 50px;
  }

  .howItWorksOwners,
  .howItWorksRenters {
    align-items: flex-start;
  }

  .howItWorksSubSection {
    flex-direction: column;
    align-items: center;
  }
}
*/

.sweepstakesBox {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  flex-direction: column; /* Stack children vertically */
  max-width: 50%;
  height: 300px;
  background: linear-gradient(to bottom, #f2f2f2, #ffffff); /* Added gradient */
  margin: auto; /* Center the box */
  text-align: center;
  min-height: 250px;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.2);
  padding: 25px;
}

.sweepstakesBox {
  margin-bottom: 50px;
}

@media (max-width: 1250px) {
  .sweepstakesBox {
    max-width: 550px;
      }
}

/* Modified: Added this media query to set the width to 100% for smaller screens */
@media (max-width: 768px) {
  .sweepstakesBox {
    width: 96%;
    margin-bottom: 50px;
    box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.2);
    justify-content: space-around;
  }
}


.sweepstakesLink {
  text-decoration: none;
  color: #333;
  display: contents;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.sweepstakesLink:hover {
  background-color: #e6e6e6;
  color: blue; /* Change to standard blue when hovered */
}

.sweepstakesBox h2 {
  margin: 0;
  padding: 0;
  font-size: 40px;
  text-align: center;
  line-height: 1.25;
}

/* Adjust font size for smaller screens */
@media (max-width: 768px) {
  .sweepstakesBox h2 {
    font-size: 30px;
    line-height: 1.25;
  }
}

@media (max-width: 480px) {
  .sweepstakesBox h2 {
    font-size: 24px;
    line-height: 1.25;
  }
}

.sweepstakesBox p {
  font-size: 14px;
  text-align: center;
}
